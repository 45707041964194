import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"
import { withPrefix } from 'gatsby'

import SoftballRendering from '../../images/spring-2020/one-pitch-at-a-time/softball-rendering.jpg';
import Ford from '../../images/spring-2020/ford.jpg';

import softball1 from '../../images/spring-2020/softball1.jpg';
import softball2 from '../../images/spring-2020/softball2.jpg';
import softball3 from '../../images/spring-2020/softball3.jpg';
// import softball4 from '../../images/spring-2020/softball4.jpg';

import socialBanner from "../../images/spring-2020/social-banners/softball-clubhouse-makeover.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Softball On Deck for a Game-Changing Clubhouse';
    var pageDesc = 'For Titan softball catcher Julia Valenzuela, this spring was supposed to be her last up-to-bat on the field and in the classroom. She was on track to graduate and her team was off to a winning season.';


    var heroStyles = {
      backgroundColor: '#222',
      // backgroundImage: `url(${SoftballRendering})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s meta">Story by Debra Cano Ramos, Photos by Matt Brown and Titan Athletics</span>
          </div>
          <div className="tint"></div>
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" poster={withPrefix('/video/softball-field.jpg')}>
            <source src={withPrefix('/video/softball-field.webm')} type="video/webm; codecs=vp9,vorbis" />
            <source src={withPrefix('/video/softball-field.mp4')} />
            <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/softball-field.vtt')} />
          </video>
        </div>
        <article className="wrap small">
 
          <ScrollAnimation animateIn="fadeIn">

          <Related slug="softball-clubhouse-makeover" />

          <p><span className="intro-text">For Titan softball catcher Julia Valenzuela</span>, this spring was supposed to be her last up-to-bat on the field and in the classroom. She was on track to graduate and her team was off to a winning season.</p>
          
          <p>That all changed just minutes before a game against Boston University on March 12 at Cal State Fullerton’s Anderson Family Field. The Big West Conference abruptly suspended all conference games due to the coronavirus pandemic. The next day, the Big West announced that the season, with only about half of the 56 scheduled games played, was canceled.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={Ford} alt="Kelly Ford high fives team members" />
            <figcaption>Kelly Ford</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“We were all in shock,” says Kelly Ford, head softball coach in her eighth season. “It was crazy. The athletes we see every day were poof — gone. The team was getting better with every game. We were disappointed to say the least, but we had to look at the bigger picture, of course.”</p>
          
          <p>For Valenzuela, and the other seniors on the 26-member team, there is a silver lining.</p>
          
          <p>The National Collegiate Athletic Association granted another year of eligibility for graduating seniors, and five of the seven seniors, including Valenzuela, are staying another year so they can finish their collegiate softball careers.</p>
          
          <p>And, by being back on the field next season, the seniors will get the chance to train, study and revel in a renovated softball clubhouse, situated next to Anderson Family Field. Construction is set to begin this summer to upgrade the dated softball facility. Completion of the project is expected during the 2021 season.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={SoftballRendering} alt="renovated softball clubhouse rendering" />
            <figcaption>Rendering of renovated softball clubhouse</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Plans call for a new, larger locker room; training room; five coaches' offices; a kitchen area; expanded patio deck; and rooms to meet, study and analyze game videos. A “Hall of Fame” area will be erected in honor of Judi Garman, the university’s first softball coach and a lead donor for the building project. Additionally, the softball batting cages will get a new shade covering.</p>
          
          <p>The renovation is part of a $13.1 million modernization project to upgrade both the existing baseball and softball facilities, with the goal to continue to recruit top athletes for the university’s nationally competitive programs.</p>

          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={softball1} alt="Softball player celebrating with team" />
            <figcaption>Samantha Galarza</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>The softball program boasts 17 conference championships, including four consecutive — 2016-19 — Big West Conference championships. Ford also is the first in conference history to earn “Coach of the Year” honors four years in a row. The program has made six College World Series appearances, and in 1986, Coach Garman led the team to a national championship title.</p>
          
          <p>“This new facility will be monumental,” said Valenzuela, who wears No. 2 on her Titan jersey and helped her team win conference championships in 2017, 2018 and 2019. “We are all very excited and cannot wait for the building to be done.</p>
          
          <figure className="align-outside large align-right">
            <img src={softball3} alt="Softball player at bat" />
            <figcaption>Arianna Williams</figcaption>
          </figure>

          <p>“Everything that we need will be in one location, providing us with more opportunities to learn more about the game of softball.”</p>
          
          <p>The national collegiate sports circle is abuzz about the upgraded facilities, shares Ford, a Portland, Oregon, native who enjoyed success as a collegiate athlete at the University of Oklahoma and is the third Titan softball coach in the program’s history.</p>
          
          <p>"This renovation is going to completely change our ability to recruit on a national level and host top tournaments. And, it will turn our athletics complex into what I call ‘Taj Mahal.’ It's going to be transformational for our students, alumni, campus community and fans.</p>
          
          <p>“You're going to see a lot of people saying ‘wow,’ Cal State Fullerton is serious about its athletics programs. It’s absolutely a game changer for the future of softball and baseball.”</p>
          
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={softball2} alt="Home plate catcher" />
            <figcaption>Julia Valenzuela</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <p>Before the 2020 season was cut short, the team was on a roll, knocking out top 25 National Collegiate Athletic Association Division 1 teams in the country, with notable wins against Central Florida, Notre Dame, Texas Tech, University of Minnesota and Arizona State.</p>
          
          <p>“This year's team figured out how to compete with every pitch. If you can do that, you’re going to end up upsetting some of those top teams in the country — and that's what we did,” Ford says.</p>
          
          <p>Looking forward to next season, Valenzuela, a human services major who plans to pursue a coaching career, says players and coaches will not only have a renewed sense of pride with the clubhouse makeover — they also still have the goal of chasing championships.</p>
          
          <p>“The Titan softball program is a family. We support each other through thick and thin,” Valenzuela adds. “All of our hard work will continue to pay off.” <span className="end-mark" /></p>

          <p className="learn-more">To learn more about Titan softball, visit <a href="https://www.fullertontitans.com" target="_blank">fullertontitans.com</a>.</p>
          </ScrollAnimation>
        </article>
        <MoreStories slug="softball-clubhouse-makeover" />
        <StoryNav slug="softball-clubhouse-makeover" />
      </Layout>
    );
  }
} 